import ThriveLogoSingleLeaf from './themeLogos/ThriveLogoSingleLeaf'
import { lightShadows } from '../shadows'
import { ExtendedThemeOptions } from '../theme'

export const thriveTheme: ExtendedThemeOptions = {
    Logo: ThriveLogoSingleLeaf,
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '&::placeholder': {
                        opacity: 0.86,
                        color: '#42526e'
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    '&:focus-visible': {
                        backgroundColor: '#360E81' // override focus color to improve contrast ratio
                    }
                }
            }
        },
        MuiDivider: {
            defaultProps: {
                role: 'presentation'
            }
        }
    },
    palette: {
        primary: {
            main: '#1E0056',
            dark: '#16003F',
            light: '#4D1AAD',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#ECEBF7',
            dark: '#D4D2EE',
            light: '#efeff8',
            contrastText: '#16003F'
        },
        accent: {
            main: '#126A78',
            dark: '#035A66',
            light: '#E8F4F5',
            contrastText: '#ffffff'
        },
        mode: 'light',
        background: {
            default: '#F7F7F8',
            paper: '#ffffff'
        },
        text: {
            primary: '#172235',
            secondary: '#4F5865',
            disabled: '#6A717D'
        },
        info: {
            main: '#ffffff',
            dark: '#F7F7F8',
            light: '#ffffff',
            contrastText: '#1E0056'
        },
        error: {
            main: '#A3292F',
            dark: '#840E13',
            light: '#FFF4F5',
            contrastText: '#ffffff'
        },
        warning: {
            main: '#F6C344',
            dark: '#663C00',
            light: '#FBF9F2',
            contrastText: '#ffffff'
        },
        success: {
            main: '#008273',
            dark: '#004D40',
            light: '#DFF5F3',
            contrastText: '#ffffff'
        },
        grey: {
            50: '#FBFBFB',
            100: '#F7F7F8',
            200: '#DBDDE0',
            300: '#BFC2C7',
            400: '#A2A6AE',
            500: '#868B95',
            600: '#6A717D',
            700: '#4F5865',
            800: '#343E4E',
            900: '#172235'
        },
        action: {
            active: '#6b778c',
            focus: 'blue'
        },
        journeys: {
            recharge: {
                main: '#BA8CFF',
                dark: '#9570CC',
                light: '#F0E6FE',
                contrastText: '#172235'
            },
            focus: {
                main: '#5DBFF0',
                dark: '#4A99C0',
                light: '#E6F5FE',
                contrastText: '#172235'
            },
            move: {
                main: '#F26B3D',
                dark: '#C25631',
                light: '#FEE6E6',
                contrastText: '#172235'
            },
            food: {
                main: '#FFB331',
                dark: '#CC8F27',
                light: '#FDF9E4',
                contrastText: '#172235'
            },
            connect: {
                main: '#FC72A5',
                dark: '#CA5B83',
                light: '#FEE6F9',
                contrastText: '#172235'
            },
            money: {
                main: '#60BF70',
                dark: '#56AC65',
                light: '#E6FEEB',
                contrastText: '#172235'
            },
            stressManagement: {
                main: '#39C6B8',
                dark: '#26A79A',
                light: '#EAFFFD',
                contrastText: '#172235'
            }
        },
        dataVisualization: {
            sequential: {
                primary: {
                    one: '#002437',
                    two: '#006092',
                    three: '#0081C2',
                    four: '#049EEB',
                    five: '#71CCFA',
                    six: '#BEE4F8',
                    seven: '#D4DCE7'
                },
                secondary: {
                    one: '#442200',
                    two: '#8D5900',
                    three: '#D59200',
                    four: '#FFB447',
                    five: '#FFCA7B',
                    six: '#FFE2B5',
                    seven: '#D4DCE7'
                }
            },
            diverging: {
                primary: {
                    one: '#8D5900',
                    two: '#FFB447',
                    three: '#FFCA7B',
                    four: '#71CCFA',
                    five: '#049EEB',
                    six: '#006092',
                    seven: '#D4DCE7'
                }
            },
            qualitative: {
                primary: {
                    one: '#BA8CFF',
                    two: '#FFB331',
                    three: '#F26B3D',
                    four: '#39C6B8',
                    five: '#FC72A5',
                    six: '#5DBFF0',
                    seven: '#60BF70',
                    eight: '#D4DCE7'
                },
                secondary: {
                    one: '#3EA7FB',
                    two: '#2F0045',
                    three: '#EF6F00',
                    four: '#0A0DBD',
                    five: '#92079C',
                    six: '#00CBB0',
                    seven: '#D4DCE7'
                }
            }
        }
    },
    gradient: {
        light: 'linear-gradient(128.32deg, #FDFDFD 3.91%, #EBF3F4 94.19%)',
        main: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%), linear-gradient(33.27deg, #EAE8FC 29.3%, rgba(241, 238, 233, 0.98) 83.39%);'
    },
    videoTextProtectionOverlay: '#000000BF',
    shadows: lightShadows,
    typography: {
        overline: {
            color: '#126A78'
        }
    }
}
