import { ExtendedThemeOptions } from '../theme'
import ThriveLogoSingleLeafOnDark from './themeLogos/ThriveLogoSingleLeafOnDark'
import { darkShadows } from '../shadows'

export const highContrastTheme: ExtendedThemeOptions = {
    Logo: ThriveLogoSingleLeafOnDark,
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                colorSecondary: {
                    color: '#000',
                    backgroundColor: '#fff'
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#ffff00',
                    backgroundColor: '#000',
                    // specificity to override Typography where needed
                    '& *[class*="MuiTypography-"]': {
                        color: '#ffff00',
                        backgroundColor: '#000'
                    }
                }
            }
        },
        MuiChip: {
            defaultProps: {
                color: 'primary'
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    bgcolor: '#fff',
                    '& svg': {
                        color: '#000',
                        fillColor: '#000'
                    }
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                    border: '1px #fff solid',
                    // hard overrides as dictated by HCM
                    '& *[class*="selected"]': {
                        color: '#000',
                        backgroundColor: '#1aebff'
                    },
                    '& *[class*="disabled"]': {
                        color: '#3ff23f'
                    },
                    '& .MuiLink': {
                        '& *[class*="MuiTypography-"]': {
                            color: '#ffff00',
                            backgroundColor: '#000'
                        }
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& button': {
                        color: '#ffff00'
                    },
                    '& *[class*="selected"] > *': {
                        color: '#000'
                    },
                    '& *[class*="selected"] ': {
                        backgroundColor: '#1aebff'
                    },
                    disabled: {
                        color: '#3ff23f',
                        backgroundColor: '#000'
                    }
                }
            }
        },
        MuiCard: {
            defaultProps: {
                variant: 'outlined'
            }
        },
        MuiButton: {
            styleOverrides: {
                text: {
                    border: '1px solid #fff'
                },

                containedSecondary: {
                    color: '#000',
                    fill: '#000',
                    backgroundColor: '#fff',
                    '&:hover': {
                        backgroundColor: 'rgba(255,255,255, 0.3)'
                    },
                    // selector war bc PulseSurveyDialogContent targets the state and not the prop
                    '&:disabled': {
                        backgroundColor: '#3ff23f !important',
                        text: '#000 !important'
                    },
                    '& path': {
                        fill: '#000'
                    }
                },
                root: {
                    '& *[class*="disabled"]': {
                        backgroundColor: '#3ff23f',
                        text: '#000'
                    },
                    '& *[class*="disabled"] > *': {
                        text: '#000'
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#3ff23f !important',
                        text: '#000 !important'
                    }
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&.MuiButton-root.Mui-disabled': {
                        backgroundColor: '#3ff23f',
                        color: '#000'
                    },
                    '&.MuiToggleButton-root.Mui-selected': {
                        color: '#000'
                    }
                }
            }
        },
        MuiSwitch: {
            variants: [
                {
                    props: {}, //all props variations will have the same color
                    style: ({ theme }) => ({
                        '& .MuiSwitch-switchBase': {
                            '&.Mui-checked': {
                                '& + .MuiSwitch-track': {
                                    backgroundColor: '#1aebff !important',
                                    opacity: 1
                                },
                                '&.Mui-disabled + .MuiSwitch-track': {
                                    backgroundColor: '#3ff23f !important'
                                }
                            },
                            '&.Mui-disabled .MuiSwitch-thumb': {
                                opacity: 1
                            },
                            '&.Mui-disabled + .MuiSwitch-track': {
                                opacity: 1,
                                border: '2px solid #3ff23f !important'
                            },
                            '&.Mui-focusVisible .MuiSwitch-thumb': {
                                outline: `2px solid ${theme.palette.action.focus}`
                            }
                        },
                        '& .MuiSwitch-thumb': {
                            color: '#000 !important'
                        },
                        '& .MuiSwitch-track': {
                            backgroundColor: '#fff !important',
                            opacity: 1
                        }
                    })
                }
            ]
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    '&.MuiPaper-root': {
                        '&.MuiAlert-standard.MuiAlert-standardSuccess, &.MuiAlert-outlined.MuiAlert-outlinedSuccess, &.MuiAlert-standard.MuiAlert-standardInfo,  &.MuiAlert-outlined.MuiAlert-outlinedInfo':
                            {
                                background: '#000',
                                '& .MuiAlertTitle-root, .MuiAlert-message, .MuiSvgIcon-root, .MuiAlert-action, .fa-regular':
                                    {
                                        color: '#fff'
                                    }
                            }
                    }
                }
            }
        },
        MuiLinearProgress: {
            defaultProps: {
                sx: {
                    color: '#FFF',
                    backgroundColor: '#000'
                }
            },
            styleOverrides: {
                root: { border: '1px solid #fff' }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: 'rgba(255,255,255,0.92)',
                    color: '#000'
                },
                arrow: {
                    color: 'rgba(255,255,255,0.92)'
                }
            }
        },
        MuiDivider: {
            defaultProps: {
                role: 'presentation'
            }
        }
    },
    palette: {
        primary: {
            main: '#fff',
            dark: '#fff',
            light: '#fff',
            contrastText: '#000'
        },
        dataVisualization: {
            sequential: {
                primary: {
                    one: '#fff',
                    two: '#fff',
                    three: '#fff',
                    four: '#fff',
                    five: '#fff',
                    six: '#fff',
                    seven: '#fff'
                },
                secondary: {
                    one: '#fff',
                    two: '#fff',
                    three: '#fff',
                    four: '#fff',
                    five: '#fff',
                    six: '#fff',
                    seven: '#fff'
                }
            },
            diverging: {
                primary: {
                    one: '#fff',
                    two: '#fff',
                    three: '#fff',
                    four: '#fff',
                    five: '#fff',
                    six: '#fff',
                    seven: '#fff'
                }
            },
            qualitative: {
                primary: {
                    one: '#fff',
                    two: '#fff',
                    three: '#fff',
                    four: '#fff',
                    five: '#fff',
                    six: '#fff',
                    seven: '#fff',
                    eight: '#fff'
                },
                secondary: {
                    one: '#fff',
                    two: '#fff',
                    three: '#fff',
                    four: '#fff',
                    five: '#fff',
                    six: '#fff',
                    seven: '#fff'
                }
            }
        },
        secondary: {
            main: '#fff',
            dark: '#fff',
            light: '#fff',
            contrastText: '#000'
        },
        accent: {
            main: '#fff',
            dark: '#fff',
            light: '#fff',
            contrastText: '#000'
        },
        mode: 'dark',
        background: {
            default: '#000',
            paper: '#000'
        },
        divider: '#fff',
        text: {
            primary: '#fff',
            secondary: '#fff',
            disabled: '#3ff23f'
        },
        info: {
            main: '#fff',
            dark: '#fff',
            light: '#fff',
            contrastText: '#000'
        },
        error: {
            main: '#fff',
            dark: '#fff',
            light: '#000',
            contrastText: '#000'
        },
        warning: {
            main: '#fff',
            dark: '#fff',
            light: '#fff',
            contrastText: '#000'
        },
        success: {
            main: '#fff',
            dark: '#fff',
            light: '#000',
            contrastText: '#000'
        },
        grey: {
            50: '#000',
            100: '#000',
            200: '#000',
            300: '#000',
            400: '#000',
            500: '#fff',
            600: '#fff',
            700: '#fff',
            800: '#fff',
            900: '#fff'
        },
        journeys: {
            recharge: {
                main: '#fff',
                dark: '#fff',
                light: '#fff',
                contrastText: '#000'
            },
            focus: {
                main: '#fff',
                dark: '#fff',
                light: '#fff',
                contrastText: '#000'
            },
            move: {
                main: '#fff',
                dark: '#fff',
                light: '#fff',
                contrastText: '#000'
            },
            food: {
                main: '#fff',
                dark: '#fff',
                light: '#fff',
                contrastText: '#000'
            },
            connect: {
                main: '#fff',
                dark: '#fff',
                light: '#fff',
                contrastText: '#000'
            },
            money: {
                main: '#fff',
                dark: '#fff',
                light: '#fff',
                contrastText: '#000'
            },
            stressManagement: {
                main: '#fff',
                dark: '#fff',
                light: '#fff',
                contrastText: '#000'
            }
        },
        action: {
            selected: '#1aebff',
            selectedOpacity: 1,
            focus: 'fuchsia'
        }
    },
    gradient: {
        main: '#000',
        light: '#000'
    },
    videoTextProtectionOverlay: '#000000BF',
    shadows: darkShadows,
    typography: {
        h1: { color: '#fff' },
        h2: { color: '#fff' },
        h3: { color: '#fff' }
    }
}
